



































































import Vue from "vue";
import { TableButtons, TableRow } from "@/types/management";
import BeheerTable from "@/components/lib/BeheerTable.vue";
import ProgressLoading from '@/components/lib/ProgressLoading.vue'

export default Vue.extend({
  name: "PopupTable",
  components: {ProgressLoading, BeheerTable },
  props: {
    title: {
      type: String,
      required: true,
    },
    themeColor: {
      type: String,
      required: false,
      default: () => "primary",
    },
    tableEndpoint: {
      type: String,
      required: true,
    },
    tableButtons: {
      type: Array as () => TableButtons[],
      required: false,
    },
    labels: {
      type: Object as any,
      required: false,
    },
    tableTitle: {
      type: String,
      required: true,
    },
    formComponentsNames: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      tableData: [] as TableRow[],
      successMessage: "",
      errorMessage: "",
      loading: true,
    };
  },
  methods: {
    getTableData() {
      this.loading = true;
      this.$request
        .get(this.tableEndpoint + "/table-data")
        .then((response: any) => {
          this.tableData = response.data;
        })
        .catch((error: any) => {
          this.$emit("error", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    create() {
      this.$request
        .post(`${this.tableEndpoint}/create`, {})
        .then((response: any) => {
          this.$emit("create", response.data);
        })
        .catch((error: any) => {
          this.$emit("error", error);
        });
    },
    handleFeedback(message: string, success: boolean) {
      if (success) {
        this.successMessage = "";
        setTimeout(() => {
          this.successMessage = message;
        }, 200);
        // this.successMessage = message
        this.errorMessage = "";
        this.getTableData();
      } else {
        this.errorMessage = "";
        setTimeout(() => {
          this.errorMessage = message;
        }, 200);
        // this.errorMessage = message
        this.successMessage = "";
      }
    },
    emitCloseDialog() {
      this.$emit("close-dialog");
    },
  },
  computed: {
    hasCreateButton(): boolean {
      return this.tableButtons?.includes(TableButtons.Create);
    },
    hasDeleteButton(): boolean {
      return this.tableButtons?.includes(TableButtons.Delete);
    },
    hasEditButton(): boolean {
      return this.tableButtons?.includes(TableButtons.Edit);
    },
    hasMigrateButton(): boolean {
      return this.tableButtons?.includes(TableButtons.Migrate);
    },
    hasVaststelButton(): boolean {
      return this.tableButtons?.includes(TableButtons.Fix);
    },
    hasForcedFixButton(): boolean {
      return this.tableButtons?.includes(TableButtons.ForcedFix);
    },
    hasTerugzetButton(): boolean {
      return this.tableButtons?.includes(TableButtons.Revert);
    },
    hasDownloadButton(): boolean {
      return this.tableButtons?.includes(TableButtons.Download);
    },
    hasArchiveButton(): boolean {
      return this.tableButtons?.includes(TableButtons.Archive);
    },
    hasImpersonateButton(): boolean {
      return this.tableButtons?.includes(TableButtons.Impersonate);
    },
  },
  mounted() {
    this.getTableData();
  },
});
