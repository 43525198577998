
































































































import Vue from "vue";
import ProgressTracker from "@/components/lib/ProgressTracker.vue";
import type { Action } from "@/types/management";
import BeheerTable from "@/components/lib/BeheerTable.vue";
import ActionController from "@/components/lib/ActionController.vue";

export default Vue.extend({
  name: "FoldableSection",
  components: {
    ActionController,
    BeheerTable,
    ProgressTracker,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    baseEndpoint: {
      type: String,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    stepNr: {
      type: Number,
      required: true,
    },
    title: String,
    instruction: String,
    actions: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      errorMessage: "",
      successMessage: "",
      status: [],
    };
  },
  methods: {
    actionIsComponent(action: Action): boolean {
      return action.name === "VueComponent";
    },
    showError(message: string) {
      this.errorMessage = message;
      this.successMessage = "";
    },
    showSuccess(message: string) {
      this.errorMessage = "";
      this.successMessage = message;
    },
    fetchStatusData() {
      if (this.name && this.baseEndpoint) {
        this.$request
          .get(this.$store.state.APIurl + this.baseEndpoint + "/status")
          .then((response: any) => {
            this.status = response.data;
          })
          .catch(() => {
            this.errorMessage =
              "Er is iets misgegaan bij het ophalen van het statusoverzicht";
          }).finally(() => {
            this.$emit("inner-action-performed");
          });
      }
    },
  },
});
