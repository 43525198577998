




import Vue from "vue";
import ManagementComponent from "@/components/management/ManagementComponent.vue";

export default Vue.extend({
  name: "Management",

  components: {
    ManagementComponent,
  },
});
