



















































































































import Vue from "vue";
import { ActionButton, ActionTypes, ErrorTypes } from "@/types/management";
import FileUpload from "@/components/lib/FileUpload.vue";
import ProgressTracker from "@/components/lib/ProgressTracker.vue";
import PopupTable from "@/components/lib/PopupTable.vue";
import axios from "axios";

export default Vue.extend({
  name: "action-controller",
  components: { FileUpload, ProgressTracker, PopupTable },
  props: {
    index: {
      type: Number,
      required: true,
    },
    sectionDisabled: {
      type: Boolean,
      default: false,
    },
    baseEndpoint: {
      type: String,
      required: false,
    },
    action: {
      type: Object as () => ActionButton,
      required: true,
    },
    width: {
      type: [Number, String],
      required: false,
      default: "100%",
    },
  },
  data() {
    return {
      actionType: ActionTypes,
      selectionList: [],
      selectedId: undefined,
      circularLoading: false,
      showProgressDialog: false,
      trackerName: "",
      showPopupTable: false,
      showCustomComponent: false,
      success: "",
      error: "",
      progressCompleted: false,
      labels: {
        Huidig: "Email",
        Beheerder: "Email",
        Overgenomen: "Email",
      },
    };
  },
  methods: {
    addQuery(url: string, query: string) {
      // add a query to a url
      const separator = url.includes('?') ? '&' : '?'
      const updatedUrl = `${url}${separator}${query}`
      return updatedUrl
    },
    saveToStore() {
      this.$store.dispatch("updateSelectedId", this.selectedId);
    },
    executeAction() {
      this.circularLoading = true;
      switch (this.action.actionType) {
        case ActionTypes.SimpleApiCall:
          this.makeApiCall();
          break;
        case ActionTypes.SelectionApiCall:
          this.makeApiCallWithSelected();
          break;
        case ActionTypes.FileDownload:
          this.downloadFile();
          break;
        case ActionTypes.FileUploadDownload:
          this.downloadFile();
          break;
        case ActionTypes.PopUpTable:
          break;
        default:
          throw new Error(
            `Execution of action type '${this.action.actionType}' is not implemented.`
          );
      }
    },
    makeApiCall() {
      this.trackerName = `${this.action.name}-${Date.now()}`

      this.$request
        .post(this.computedUrl, {}, { timeout: 8000 })
        .then((response: any) => {
          let message =
            this.action.successMessage ?? "Actie is succesvol uitgevoerd.";
          if(typeof response?.data === 'string' && response?.data !== '') {
            message = response.data
          }

          this.passFeedback(true, message, this.trackerName);
        })
        .catch((error: any) => {
          console.log(error);
          if (error.response?.data?.detail) {
            let message = error.response.data.detail
            this.passFeedback(false, message);
            return;
          }
          if (error.response?.status === 401) {
            let message =
              this.action.errorMessages?.[ErrorTypes.UNAUTHORIZED] ??
              "U bent niet geautoriseerd om deze actie uit te voeren. Bent u nog wel ingelogd?";
            this.passFeedback(false, message);
            return;
          }
          if (error.code === "ECONNABORTED") {
            this.passFeedback(
              false,
              "De server heeft het druk. De actie zal vanzelf hervat worden zodra het rustiger is."
            );
            return;
          }
          // Check if statuscode is 404
          let message =
            this.action.errorMessages?.[ErrorTypes.DEFAULT] ??
            "Er is iets misgegaan";
          if (error.response?.status === 404) {
            message =
              this.action.errorMessages?.[ErrorTypes.FILE_NOT_FOUND] ??
              "Het opgevraagde item is niet gevonden.";
          }
          this.passFeedback(false, message);
        })
        .finally(() => {
          this.circularLoading = false;
        });
    },
    makeApiCallWithSelected() {
      if (this.selectedId) {
        // See url with selected id in computedUrl()
        this.makeApiCall();
      } else {
        const message =
          this.action.errorMessages?.[ErrorTypes.NO_SELECTION] ??
          "Er is nog geen selectie gemaakt";
        this.passFeedback(false, message);
      }
    },
    downloadFile() {
      if (!this.action.fileType) {
        const feedbackMessage = "Er is iets misgegaan bij het downloaden.";
        this.passFeedback(false, feedbackMessage);
        this.circularLoading = false;
        throw new Error(
          "FileType is not defined SectionList. Please specify the file type when using FileDownload actionType."
        );
      } else if (!this.action.outputFileName) {
        const feedbackMessage = "Er is iets misgegaan bij het downloaden";
        this.passFeedback(false, feedbackMessage);
        this.circularLoading = false;
        throw new Error(
          "outputFileName is not defined in SectionList. Please specify the file name when using FileDownload actionType."
        );
      }
      this.circularLoading = true;
      axios({
        url: this.computedUrl,
        method: "GET",
        responseType: "blob",
        headers: { Authorization: `Bearer ${this.$store.state.user.token}` },
      })
        .then((response: any) => {
          this.circularLoading = false;
          const fileURL = window.URL.createObjectURL(
            new Blob([response.data], {
              type: this.action.fileType,
            })
          );
          const fileLink = document.createElement("a");
          fileLink.href = fileURL;
          fileLink.setAttribute(
            "download",
            this.action.outputFileName ?? "resultaat"
          );
          document.body.appendChild(fileLink);
          fileLink.click();
        })
        .catch((error: any) => {
          this.circularLoading = false;
          if (error.code === "ECONNABORTED") {
            this.passFeedback(
              false,
              "De server heeft het druk. De actie zal vanzelf hervat worden zodra het rustiger is."
            );
            return;
          }
          let message =
            this.action.errorMessages?.[ErrorTypes.DEFAULT] ??
            "Er is iets misgegaan";
          if (error.response?.status === 404) {
            message =
              this.action.errorMessages?.[ErrorTypes.FILE_NOT_FOUND] ??
              "Het opgevraagde bestand is niet gevonden.";
          }
          if (error.response.data.detail) {
            message = error.response.data.detail;
          }
          this.passFeedback(false, message);
          console.log(error);
        });
    },
    passFeedback(success: boolean, message: string, trackerName="") {
      if (success) {
        this.error = "";
        // TrackerName can be defined in either makeApiCall() or a child component
        this.trackerName = trackerName
        if (this.action.showProgress === true) {
          this.showProgressDialog = true;
          this.progressCompleted = false;
        } else {
          this.success = message;
          this.$emit("actionSuccess", message);
        }
        this.$emit("actionCompleted", this.action.name);
      } else {
        this.success = "";
        this.error = message;
        this.$emit("actionError", message);
      }
    },
    onProgressCompleted() {
      if (this.action.actionType === ActionTypes.FileUploadDownload) {
        this.downloadFile()
      }
      this.$emit("actionCompleted", this.action.name);
      this.progressCompleted = true;
    },
    checkActionIsAllowed() {
      // check if attribute name exists on action
      // if (this.action.name) {
      //   const headers = {Authorization: `Bearer ${this.$store.state.user.token}`}
      //   this.$request      //     .get(
      //       this.actionDataUrl,
      //       { headers }
      //     )
      //     .then((response) => {
      //       this.actionAllowed = response.data
      //     })
      //     .catch((error) => {
      //       this.actionAllowed = false
      //       console.log(error)
      //     })
      // }
    },
    closePopupTable() {
      this.showPopupTable = false;
      this.$emit("actionCompleted");
    },
  },
  computed: {
    computedUrl(): string {
      let url: string;
      if (this.action.actionType === ActionTypes.SelectionApiCall) {
        const idParam = String(this.selectedId);
        url = this.action.actionEndpoint?.replace("{}", idParam)  || '';
      } else if (this.action.actionType === ActionTypes.FileUpload) {
        url = `${this.baseEndpoint}/${this.action.name}`;
      } else if (this.action.actionType === ActionTypes.FileUploadDownload) {
        url = `${this.baseEndpoint}/${this.action.name}`;
      } else if (this.action.actionType === ActionTypes.PopUpTable) {
        url = `${this.baseEndpoint}/${this.action.name}`;
      } else if (this.action.actionType === ActionTypes.FileDownload) {
        const idParam = String(this.$store.state.selectedId);
        url = `${this.baseEndpoint}/${this.action.name}`;
        if (idParam) {
          url = this.addQuery(url ,`item_id=${idParam}`)
        }
      } else {
        url =
          this.action.actionEndpoint ??
          this.baseEndpoint + "/" + this.action.name;
      }

      if (this.action.showProgress === true && this.trackerName) {
        url = this.addQuery(url, `tracker_name=${this.trackerName}`)
      }

      return this.$store.state.APIurl + url;
    },
    persistProgressDialog(): boolean {
      return !(this.error || this.success || this.progressCompleted);
    },
    getTitle(): string {
      if (this.action?.tableTitle) {
        return this.action.tableTitle.replace(/(en|s)$/, "beheer");
      } else {
        return "Beheer";
      }
    },
    actionDisabled(): boolean {
      return (this.sectionDisabled || this.action.disabled) ?? false;
    },
  },
  mounted() {
    this.checkActionIsAllowed();
    if (this.action.actionType === ActionTypes.SelectionApiCall) {
      if (this.action.additionalEndpoint) {
        this.$request
          .get(this.$store.state.APIurl + this.action.additionalEndpoint)
          .then((response: any) => {
            this.selectionList = response.data;
          })
          .catch(() => {
            const message =
              "Er is iets misgegaan bij het ophalen van de selectielijst";
            this.passFeedback(false, message);
          });
      }
    }
  },
});
