export type SectionList = Section[];

interface Section {
  name: string;
  title: string;
  instruction?: string;
  actions: Action[];
  baseEndpoint: string;
  disabled?: boolean;
}

export type Action = ActionButton;

export interface ActionButton {
  name?: string;
  instruction?: string;
  buttonText: string;
  actionType: ActionTypes;
  themeColor?: string;
  actionEndpoint?: string;
  additionalEndpoint?: string;
  availabilityCheck?: string;
  showProgress?: boolean;
  tableTitle?: string;
  tableButtons?: TableButtons[];
  tableFormComponents?: string[];
  errorMessages?: ErrorType;
  successMessage?: string;
  fileType?: string;
  outputFileName?: string;
  disabled?: boolean;
  componentName?: string;
  disableLoading?: boolean;
}

export enum TableButtons {
  Create,
  Edit,
  Migrate,
  Archive,
  Delete,
  Fix,
  Revert,
  Download,
  SendNotification,
  Impersonate,
  ForcedFix
}

export interface TableRow {
  id: number;
  [key: string]: string | number;
}

export enum ActionTypes {
  SimpleApiCall,
  SelectionApiCall,
  PopUpTable,
  PageNavigation,
  FileDownload,
  FileUpload,
  FileUploadDownload,
  CustomComponent,
}

export enum ErrorTypes {
  DEFAULT,
  NO_SELECTION,
  FILE_NOT_FOUND,
  UNAUTHORIZED,
}

export type ErrorType = {
  [key in ErrorTypes]?: string;
};
