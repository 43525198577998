








































import Vue from "vue";
import FoldableSection from "@/components/lib/FoldableSection.vue";
import sectionList from "@/components/management/sections";
import ProgressLoading from '@/components/lib/ProgressLoading.vue'

export default Vue.extend({
  name: "ManagementComponent",
  components: {
    ProgressLoading,
    FoldableSection,
  },
  data() {
    return {
      sectionList,
      section_available: {},
    };
  },
  computed: {
    loading(): boolean {
      return Object.keys(this.section_available).length === 0;
    },
  },
  methods: {
    checkAvailability() {
      this.$request
        .get(this.$store.state.APIurl + "/progress/availability-info")
        .then((response: any) => {
          this.section_available = response.data;
        })
        .catch((error: any) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.checkAvailability();
  },
});
